import styled, { css } from 'styled-components'
import { applyModifiers } from 'common/functions/'

export type T_ImageItemImage = keyof typeof Modifiers
export interface I_ImageItemImage {
  modifiers?: T_ImageItemImage[]
}

const Modifiers = {
  scaleInHeight: () => css`
    height: 100%;
  `
}

export const ImageItemImage = styled.img<I_ImageItemImage>`
  display: block;
  margin: 0 auto;
  width: 100%;
  object-fit: contain;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
