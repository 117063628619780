import styled from 'styled-components'
import { motion as m } from 'framer-motion'
import { pxToRem } from 'common/functions'
import { gaps, breaks } from 'common/styles/settings'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    sizes: { tertiary, paragraph }
  }
} = Mods

export const OverlayContent = styled(m.div)`
  /* padding: ${gaps.xl}; */
  width: 100%;
  max-width: ${pxToRem(720)};
  background-color: white;

  /* ${breaks.md.up} {
    padding: ${gaps.xxl};
  }

  ${breaks.lg.up} {
    padding: ${gaps.xxxl};
  } */

  & h3 {
    /* ${tertiary} */
    color: #c4c4c4;
  }

  & ul {
    padding: 0 0 ${gaps.xl} ${gaps.l};
    list-style: none;

    & li {
      /* ${paragraph} */
      padding: 0 0 ${gaps.m} 0;
    }
  }
`
