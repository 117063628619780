import styled from 'styled-components'
import { Modifiers as Mods } from 'common/styles/modifiers'

const {
  font: {
    sizes: { xSmall }
  }
} = Mods

export const ImagesItemDetails = styled.div`
  & p {
    ${xSmall}
    color: #c4c4c4;
  }
`
