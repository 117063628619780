import styled from 'styled-components'

import { Modifiers as Mods } from 'common/styles/modifiers'
import { T_Spacing } from 'common/types'
import { applyModifiers } from 'common/functions'

export type T_Images = keyof typeof Modifiers
export interface I_Images {
  modifiers?: T_Images[]
  spacingTop?: T_Spacing
  spacingBottom?: T_Spacing
}

const {
  spacings: { spacingTop, spacingBottom }
} = Mods

const Modifiers = {
  spacingTop,
  spacingBottom
}

export const Images = styled.div<I_Images>`
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
