import React, { useState, createContext, useEffect, useRef } from 'react'
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { motion as m, AnimatePresence as Ap } from 'framer-motion'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import { ImagesItemProps as I_ImagesItem } from 'components/Images/ImagesItem'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'

import { OverlayStyles as O } from 'components/Overlay/styles'
import { ImagesStyles as I } from 'components/Images/styles'
import { animation as a } from 'components/Images/animation'

interface OverlayProviderProps {
  children: any
}

type T_Overlay = 'image' | 'content' | null
type T_OverlayContent = I_ImagesItem | JSX.Element | null
type T_ToggleOverlay = (type: T_Overlay, content: T_OverlayContent) => void

export interface I_ContextState {
  isVisible: boolean
  content: T_OverlayContent
  type: T_Overlay
  toggleOverlay: T_ToggleOverlay
}

const defaultState = {
  isVisible: false,
  content: null,
  type: null,
  toggleOverlay: (type: T_Overlay, content: T_OverlayContent) => {}
}

export const OverlayContext = createContext(defaultState)

export const OverlayProvider = ({ children }: OverlayProviderProps) => {
  const refOverlay = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<I_ContextState>(defaultState)

  const toggleOverlay = (type: T_Overlay, content: T_OverlayContent) => {
    setState(() => ({ ...state, isVisible: !state.isVisible, type, content }))
  }

  useEffect(() => {
    refOverlay.current && state.isVisible ? enableBodyScroll(refOverlay.current) : clearAllBodyScrollLocks()
  }, [state.isVisible, refOverlay])

  const renderClose = () => {
    return (
      <m.div initial={'out'} animate={'in'} exit={'out'} variants={a.overlayButton}>
        <div
          onClick={() => {
            setState({ ...state, isVisible: false, type: null, content: null })
          }}
          style={{
            width: '48px',
            height: '48px',
            backgroundColor: 'white',
            borderRadius: '50%',
            cursor: 'pointer'
          }}
        >
          <Icon type={'close'} />
        </div>
      </m.div>
    )
  }

  const renderZoomImage = () => {
    const imageItem = state.content as I_ImagesItem
    return (
      <TransformWrapper>
        <TransformComponent>
          <I.ImagesItem.Image
            modifiers={['scaleInHeight']}
            src={
              state.content !== null && imageItem.imageBig.filename !== ''
                ? imageItem.imageBig.filename
                : imageItem.image.filename
            }
          />
        </TransformComponent>
      </TransformWrapper>
    )
  }

  const renderOverlayContent = () => {
    const content = state.content as JSX.Element
    return content
  }

  const renderInquiry = () => {
    const { title } = state.content as I_ImagesItem
    return (
      <div style={{ position: 'absolute', zIndex: 30, bottom: 24, left: 24 }}>
        <a href={`mailto:mail@jeanclaudemaier.com?subject=${title || 'general'}`}>
          <Text type={'span'} modifiers={['xSmall', 'textTransform', 'colorInverted']}>
            Inquiry
          </Text>
        </a>
      </div>
    )
  }

  return (
    <>
      <OverlayContext.Provider value={{ ...state, toggleOverlay }}>
        <>
          {children}
          <Ap exitBeforeEnter>
            {state.isVisible && state.type === 'image' && (
              <I.ImagesItem.Overlay ref={refOverlay} initial={'out'} animate={'in'} exit={'out'} variants={a.overlay}>
                {renderClose()}
                {renderZoomImage()}
                {renderInquiry()}
              </I.ImagesItem.Overlay>
            )}
            {state.isVisible && state.type === 'content' && (
              <O.Item
                ref={refOverlay}
                modifiers={['onWhite']}
                initial={'out'}
                animate={'in'}
                exit={'out'}
                variants={a.overlay}
              >
                {renderClose()}
                {renderOverlayContent()}
              </O.Item>
            )}
          </Ap>
        </>
      </OverlayContext.Provider>
    </>
  )
}

export default OverlayProvider
