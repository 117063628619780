import styled, { css } from 'styled-components'

import { applyModifiers, pxToRem } from 'common/functions'

export type T_ImagesItem = keyof typeof Modifiers
export interface I_ImagesItem {
  modifiers?: T_ImagesItem[]
}

const Modifiers = {
  isGallery: () => css`
    max-width: 50vw;
    min-width: ${pxToRem(280)};
    max-height: 100vh;
  `
}

export const ImagesItem = styled.div<I_ImagesItem>`
  flex: 1;
  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
