import styled from 'styled-components'
import { containerWidth } from 'common/styles/settings'

export const ImagesItemFigure = styled.figure`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0 auto;
  max-width: ${containerWidth.default};
  height: 100%;
`
