import React from 'react'
import { ReactComponent as IconArrowLeft } from 'common/icons/icon-arrow-left.svg'
import { ReactComponent as IconArrowRight } from 'common/icons/icon-arrow-right.svg'
import { ReactComponent as IconArrowDown } from 'common/icons/icon-arrow-down.svg'
import { ReactComponent as IconPlus } from 'common/icons/icon-plus.svg'
import { ReactComponent as IconClose } from 'common/icons/icon-close.svg'
import { ReactComponent as IconMagnify } from 'common/icons/icon-magnify.svg'

export enum Icons {
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  arrowDown = 'arrow-down',
  close = 'close',
  plus = 'plus',
  magnify = 'magnify'
}

export type T_Icon = Record<Icons, string>
export type T_Type = Record<keyof typeof Icons, string>

export interface IconProps {
  type: any
  width?: number
  height?: number
  classes?: string
}

export const Icon: React.FC<IconProps> = ({ type, width, height, classes }) => {
  switch (type) {
    case Icons.magnify:
      return <IconMagnify className={classes} width={width} height={height} />
    case Icons.arrowLeft:
      return <IconArrowLeft className={classes} width={width} height={height} />
    case Icons.arrowRight:
      return <IconArrowRight className={classes} width={width} height={height} />
    case Icons.arrowDown:
      return <IconArrowDown className={classes} width={width} height={height} />
    case Icons.close:
      return <IconClose className={classes} width={width} height={height} />
    case Icons.plus:
      return <IconPlus className={classes} width={width} height={height} />
    default:
      return null
  }
}
