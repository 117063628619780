import styled from 'styled-components'
import { breaks } from 'common/styles/settings'
import { spacers } from 'common/constants'
import { pxToRem } from 'common/functions'

export const ImagesItemImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin: 0 auto;
  padding: ${spacers.xl};
  width: 100%;
  max-width: ${pxToRem(1200)};
  height: 50vh;
  min-height: ${pxToRem(300)};
  max-height: ${pxToRem(400)};

  ${breaks.lg.up} {
    min-height: ${pxToRem(500)};
    max-height: ${pxToRem(700)};
  }

  ${breaks.xl.up} {
    padding: ${spacers.xxl};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
