import { Variants, Variant } from 'framer-motion'
import { spacers, transition, ease } from 'common/constants'

const styles: Variant = {
  position: 'fixed',
  zIndex: 20,
  top: '50%',
  left: '50%',
  x: '-50%',
  y: '-50%',
  width: '100%',
  height: '100%'
}

const stylesButton: Variant = {
  position: 'absolute',
  zIndex: 3,
  top: `${spacers.xl}`,
  right: `${spacers.xl}`
}

export const animation: { overlay: Variants; overlayButton: Variants } = {
  overlay: {
    in: {
      ...styles,
      opacity: 1,
      scale: 1,
      transition: {
        ease,
        duration: 0.75
      }
    },
    out: {
      ...styles,
      opacity: 0,
      scale: 1.1,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.5
      }
    }
  },
  overlayButton: {
    in: {
      ...stylesButton,
      opacity: 1,
      scale: 1,
      transition: {
        ease,
        duration: 0.5,
        delay: 0.5
      }
    },
    out: {
      ...stylesButton,
      opacity: 0,
      scale: 0.8,
      transition: {
        ease,
        duration: 0.5
      }
    }
  }
}
