import styled, { css } from 'styled-components'
import { motion as m } from 'framer-motion'

import { applyModifiers } from 'common/functions'

export type T_Overlay = keyof typeof Modifiers
export interface I_Overlay {
  modifiers?: T_Overlay[]
}

const Modifiers = {
  onWhite: () => css`
    background-color: white;
  `
}

export const Overlay = styled(m.div)<I_Overlay>`
  position: fixed;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;

  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
