import styled from 'styled-components'

import { applyModifiers } from 'common/functions/'
import { Modifiers as Mods } from 'common/styles/modifiers'
import { T_Spacing } from 'common/types'

const {
  spacings: { spacingTop, spacingBottom }
} = Mods

export type T_ImagesItemFigcaption = keyof typeof Modifiers

export interface I_ImagesItemFigcaption {
  modifiers?: T_ImagesItemFigcaption[]
  spacingTop?: T_Spacing
  spacingBottom?: T_Spacing
}

const Modifiers = {
  spacingTop,
  spacingBottom
}

export const ImagesItemFigcaption = styled.figcaption<I_ImagesItemFigcaption>`
  ${(props) => applyModifiers(Modifiers, props.modifiers)}
`
