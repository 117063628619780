import { Images } from './Images'
import { ImagesItem as Item } from './ImagesImageItem'
import { ImageItemImage as Image } from './ImageItemImage'
import { ImagesItemDetails as Details } from './ImagesItemDetails'
import { ImagesItemDragWrapper as DragWrapper } from './ImagesItemDragWrapper'
import { ImagesItemImageWrapper as ImageWrapper } from './ImagesItemImageWrapper'
import { ImagesItemFigcaption as Figcaption } from './ImagesItemFigcaption'
import { ImagesItemOverlay as Overlay } from './ImagesItemOverlay'
import { ImagesItemFigure as Figure } from './ImagesItemFigure'
import { ImagesProgress as Progress } from './ImagesProgress'
import { ImagesProgressIndicator as Indicator } from './ImagesProgressIndicator'

export const ImagesStyles = {
  Images,
  DragWrapper,
  Progess: {
    Item: Progress,
    Indicator
  },
  ImagesItem: {
    Item,
    Details,
    Figcaption,
    Figure,
    Image,
    ImageWrapper,
    Overlay
  }
}
