import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ImagesItemOverlay = styled(motion.div)`
  position: fixed;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;

  & .react-transform-wrapper,
  & .react-transform-component {
    width: 100%;
    height: 100vh;
  }
`
